import React from 'react';
import {


  Tabs,
  Tab,
  StructuredListWrapper,
  StructuredListBody,
  StructuredListRow,
  StructuredListCell,

} from 'carbon-components-react';
import { InfoSection, InfoCard } from '../../components/Info';
import { Add, Map, LogoLinkedin, SkillLevelAdvanced, Badge, Download } from '@carbon/icons-react';
import { Tag, IconButton, Button } from '@carbon/react';
const props = {
  tabs: {
    selected: 0,
    role: 'navigation',
  },
  tab: {
    role: 'presentation',
    tabIndex: 0,
  },
};

const LandingPage = () => {
  const contact = [
    "November 26, 1986",
    "Riyadh, Saudi Arabia",
    "ak.ahmedkoubaa@gmail.com",
    "+966-507538186"]
  return (
    <div className="bx--grid bx--grid--full-width landing-page">

      <InfoSection img="Ahmed.jpg">
        <InfoCard
          heading="Ahmed Koubaa"
          subheading="IT Development Manager"
          body="Looking for Digital Transformation? Here I am!"

          icon={<a href='https://www.linkedin.com/in/ahmed-koubaa86/' target='_blank'><LogoLinkedin style={{ marginTop: "2rem" }} size={36} /></a>}
        >
          <p>More than 12 Years of challenges and achievements in Asset Management industry</p>
        </InfoCard>
        <InfoCard
          heading="Contact Info"
          subheading=""
          body=""
          icon={<a href='#'><Map style={{ marginTop: "2rem" }} size={36} /></a>}
        >
          <StructuredListWrapper style={{ marginBottom: "unset" }} selection >
            <StructuredListBody>
              <StructuredListRow>
                <StructuredListCell noWrap>Birthdate</StructuredListCell>
                <StructuredListCell>November 26, 1986</StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>Location</StructuredListCell>
                <StructuredListCell>Riyadh, KSA</StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>Email</StructuredListCell>
                <StructuredListCell>ak.ahmedkoubaa@gmail.com</StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>Phone</StructuredListCell>
                <StructuredListCell>+966-507538186</StructuredListCell>
              </StructuredListRow>
            </StructuredListBody>
          </StructuredListWrapper>
        </InfoCard>
        <InfoCard
          heading="Certifications Badges"
          subheading=""
          body=""
          icon={<a href='#'><Badge style={{ marginTop: "2rem" }} size={36} /></a>}
        >
          <StructuredListWrapper style={{ marginBottom: "unset" }} selection>
            <StructuredListBody>
              <StructuredListRow>
                <StructuredListCell noWrap>Project Management Professional</StructuredListCell>
                <StructuredListCell><a href={`${process.env.PUBLIC_URL}/pmp.pdf`} target="_blank" ><Download /></a></StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>IBM Maximo Deployment</StructuredListCell>
                <StructuredListCell><a href={`${process.env.PUBLIC_URL}/deployment.pdf`} target="_blank"><Download /></a></StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>IBM Maximo Advisor</StructuredListCell>
                <StructuredListCell><a href={`${process.env.PUBLIC_URL}/advisor.pdf`} target="_blank"><Download /></a></StructuredListCell>
              </StructuredListRow>
              <StructuredListRow>
                <StructuredListCell noWrap>IBM Maximo Analyst</StructuredListCell>
                <StructuredListCell><a href={`${process.env.PUBLIC_URL}/analyst.pdf`} target="_blank">
                  <Download />
                </a></StructuredListCell>
              </StructuredListRow>
            </StructuredListBody>
          </StructuredListWrapper>
        </InfoCard>
      </InfoSection>
      <br></br>
      <br></br>
      <hr></hr>
      <br></br>
      <Tag type="red" title="Clear Filter"> IBM Maximo EAM </Tag>
      <Tag type="cyan" title="Clear Filter"> CMMS </Tag>
      <Tag type="magenta" title="Clear Filter"> IBM Optim Data Archiving </Tag>
      <Tag type="warm-gray" title="Clear Filter"> Reporting </Tag>
      <Tag type="purple" title="Clear Filter"> DB2 </Tag>
      <Tag type="teal" title="Clear Filter"> Deliverables Management </Tag>
      <Tag type="blue" title="Clear Filter"> Oracle </Tag>
      <Tag type="blue" title="Clear Filter"> Functional Specifications </Tag>
      <Tag type="cyan" title="Clear Filter"> SQL Server </Tag>
      <Tag type="orange" title="Clear Filter"> Integration </Tag>
      <Tag type="teal" title="Clear Filter"> Business analysis </Tag>
      <Tag type="green" title="Clear Filter"> Customization </Tag>
      <Tag type="green" title="Clear Filter"> Strategic Planning </Tag>
      <Tag type="gray" title="Clear Filter"> IBM Cognos </Tag>
      <Tag type="cool-gray" title="Clear Filter"> Project Management </Tag>
      <Tag type="cool-gray" title="Clear Filter"> Leadership </Tag>
      <Tag type="purple" title="Clear Filter"> Data Modeling </Tag>
      <Tag type="magenta" title="Clear Filter"> Key Performance Indicators (KPI) </Tag>
      <Tag type="orange" title="Clear Filter"> Workflow Management </Tag>
      <Tag type="red" title="Clear Filter"> Training </Tag>
      <br></br>
      <br></br>
      <br></br>
      <div className="bx--row landing-page__r2">
        <div className="bx--col bx--no-gutter">
          <Tabs {...props.tabs} aria-label="Tab navigation">
            <Tab {...props.tab} label="About">
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">

                <div className="bx--row landing-page__tab-content">

                  <div className="bx--col-md-4 bx--col-lg-7">
                    <h2 className="landing-page__subheading">
                      Profile
                    </h2>
                    <p className="landing-page__p">
                      An experienced Engineer in the deployment/development of the CMMS and managing the implementation of Digital solutions including project management, maintenance planning, risk management, integration, analysis of business processes, and functional requirements.
                      Technical and functional expert of IBM Maximo software packages.  Skilled consultant in the implementation of maintenance processes for major accounts projects.
                    </p>
                    <Button onClick={() => {
                      window.open(`${process.env.PUBLIC_URL}/resume.pdf`);
                    }}>Download CV</Button>
                  </div>
                  <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
                    <img
                      className="landing-page__illo"
                      src={`${process.env.PUBLIC_URL}/tab-illo.png`}
                      alt="Carbon illustration"
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab {...props.tab} label="Key Achievements">
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">

                <div className="bx--row landing-page__tab-content">

                  <div className="bx--col-md-4 bx--col-lg-7">
                    <h2 className="landing-page__subheading">
                      Key Achievements
                    </h2>
                    <p className="landing-page__p">
                      <ul style={{ listStyle: "square" }}>
                        <li>Lead the Operations Planning & Performance and CMMS Teams within the Program Management and Supervision (PMS) of the Royal Commission for Riyadh City (RCRC) for the Riyadh Metro Project.</li>
                        <li>Technical Advisor for integrating the communication link between the Riyadh Metro (TCC and the OCCs) and the Unified Security Operations Center (911) for Traffic Alarms and Incidents.</li>
                        <li>Responsible for leading the technical & functional specification and the client inputs on the development of the “Site visit web application” designed for the Riyadh Metro Project.</li>
                        <li>Lead business process mapping and development of the new workflows for: Waiver process, Failure Reporting and Corrective Action System (FRACAS) process, Decommissioning process.</li>
                        <li>Design and Implementation of a new MAXIMO application "Management of performance parameters" treated as a new concept independent of SLAs to manage performance control and calculate the penalties induced by non-compliance of these parameters between the two Dalkia companies at the CHUM.</li>
                      </ul>
                    </p>
                    <Button onClick={() => {
                      window.open(`${process.env.PUBLIC_URL}/resume.pdf`);
                    }}>Download CV</Button>
                  </div>
                  <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
                    <img
                      className="landing-page__illo"
                      src={`${process.env.PUBLIC_URL}/tab-illo.png`}
                      alt="Carbon illustration"
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab {...props.tab} label="Personal Strengths">
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">

                <div className="bx--row landing-page__tab-content">

                  <div className="bx--col-md-4 bx--col-lg-7">
                    <h2 className="landing-page__subheading">
                      Personal Strengths
                    </h2>
                    <p className="landing-page__p">
                      <ul style={{ listStyle: "square" }}>
                        <li>Designing the functional requirements process and orchestrating other digital resources.</li>
                        <li>Solid knowledge of interfacing/integrating systems via APIs.</li>
                        <li>Good client relationships through gathering, interpreting, and managing their requirements.</li>
                        <li>Contribute to the high availability of the Client systems, in order to maximize the uptime at the lowest possible maintenance cost.</li>
                        <li>Expert in the “application designer” and configuration of Assets within MAXIMO products.</li>
                        <li>Solid knowledge of MAXIMO Scheduler.</li>
                        <li>Excellent ability to lead workshops, Business Blueprint meetings, UAT meetings, and conduct training on the integrated functionalities.</li>
                        <li>Manage multiple technical environments and support the development and testing processes.</li>
                        <li>Collaborate with the technical team and support them technically via providing the technical solution.</li>
                        <li>Identify areas of customization and optimization and provide solutions that meet the business requirements.</li>
                        <li>Seek effective business solutions through improvements of workflows, ways of work (best practices), as well as the provision of digital products.</li>
                        <li>Supporting bid and proposal work to provide project cost estimates.</li>
                      </ul>
                    </p>
                    <Button onClick={() => {
                      window.open(`${process.env.PUBLIC_URL}/resume.pdf`);
                    }}>Download CV</Button>
                  </div>
                  <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
                    <img
                      className="landing-page__illo"
                      src={`${process.env.PUBLIC_URL}/tab-illo.png`}
                      alt="Carbon illustration"
                    />
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>

    </div>
  );
};

export default LandingPage;
