import React from 'react';

// Take in a phrase and separate the third word in an array
function createArrayFromPhrase(phrase) {
  const splitPhrase = phrase.split(' ');
  const thirdWord = splitPhrase.pop();
  return [splitPhrase.join(' '), thirdWord];
}

const InfoSection = props => (
  <section className={`bx--row ${props.className} info-section`}>
    <div className="bx--col-md-8 bx--col-lg-4 bx--col-xlg-3" style={{paddingTop:"1rem",textAlign:"center",margin:"1rem !important",   display: "flex", alignItems:"center",justifyContent:"center"   }}>
      <h3 className="info-section__heading">{props.heading}</h3>
      <div style={{display:"flex",justifyContent:"center"}}>
      <img
            className="landing-page__illo"
            src={`${process.env.PUBLIC_URL}/${props.img}`} style={{ width: '13em', height: '18em' }}
            alt="Carbon illustration"
          />
          </div>
    </div>
    {props.children}
  </section>
);

const InfoCard = props => {
  const splitHeading = createArrayFromPhrase(props.heading);
const subheading = props.subheading;
  return (
    <div className="info-card bx--col-md-4 bx--col-lg-4 bx--col-xlg-3 bx--offset-xlg-1">
      <h2 >
        {`${splitHeading[0]} `}
        <strong>{splitHeading[1]}</strong>
      </h2>
      <h4>{subheading}</h4>
      {props.contact && props.contact.map((e)=>{
        return <p>{e}</p>
      })}
      <p className="info-card__body">{props.body}</p>
      {props.children}
      {props.icon}
    </div>
  );
};

export { InfoSection, InfoCard };
